import Instagram from "./instagram";
import YouTube from "./youtube";

function App() {
  const openURLInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="container">
      <img src="/logo.png" alt="raffiner logo" width="256" />
      <div className="social">
        <Instagram
          onClick={() =>
            openURLInNewTab("https://www.instagram.com/raffiner.mesaposta")
          }
        />
        <YouTube
          className="youtube"
          onClick={() =>
            openURLInNewTab("https://www.youtube.com/channel/UC5rj-dlZG5HjRP5u3SQ5zzA")
          }
        />
      </div>
    </div>
  );
}

export default App;
